export function checkValidatePassword (v) {
  let count = 0
  // nhap so
  const regex1 = /^(?=.*\d)/
  // ky tu thuong
  const regex2 = /(?=.*[a-z])/
  // ky tu hoa
  const regex3 = /(?=.*[A-Z])/
  // ky tu dac biet
  const regex4 = /(?=.*[!@#$%^&*])/
  if (regex1.test(v)) {
    count = count + 1
  }
  if (regex2.test(v)) {
    count = count + 1
  }
  if (regex3.test(v)) {
    count = count + 1
  }
  if (regex4.test(v)) {
    count = count + 1
  }
  if (count >= 2 && v.length >= 8) {
    return true
  } else return false
}
